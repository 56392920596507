import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  @media (max-width: 600px) {
    margin: 0 0 0 3px;
    width: 11px;
    height: 10px;
  }
`;

export const Marker = () => (
  <Icon
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6.125" stroke="black" strokeWidth="0.75" />
  </Icon>
);

export const ActiveMarker = () => (
  <Icon
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="6.5"
      cy="6.5"
      r="6.125"
      fill="#FAFAF5"
      stroke="black"
      strokeWidth="0.75"
    />
    <path d="M2 11L11 2" stroke="black" strokeWidth="0.75" />
  </Icon>
);
