import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import LazyLoad from "react-lazyload";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { ConditionalWrapper } from "../utils/conditional-wrapper";

//Utils
import kebabCase from "lodash.kebabcase";
import { FormatProductTitle } from "../utils/format-product-title";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
  }

  &.grid-view {
    & .object {
      grid-column: span 4;

      @media (max-width: 767px) {
        grid-column: span 3;
      }
    }
  }

  &.gallery-view {
    & .object {
      &:nth-of-type(11n + 1) {
        grid-column: 9 / 13;

        @media (max-width: 767px) {
          grid-column: 1 / 5;
        }
      }

      &:nth-of-type(11n + 2) {
        grid-column: 1 / 7;

        margin-top: -59%;
        margin-left: -20px;

        & .container {
          padding-left: 20px;
        }

        @media (max-width: 767px) {
          grid-column: 2 / 7;

          margin-top: 0;
          margin-left: 0;
          margin-right: -15px;

          & .container {
            padding-left: 0;
            padding-right: 15px;
          }
        }
      }

      &:nth-of-type(11n + 3) {
        grid-column: 7 / 11;

        margin-top: 70%;

        @media (max-width: 767px) {
          margin-top: 0;
          grid-column: 3 / 7;
        }
      }

      &:nth-of-type(11n + 4) {
        grid-column: 2 / 6;

        margin-top: -16%;

        @media (max-width: 767px) {
          margin-top: 0;
          grid-column: 1 / 4;

          margin-left: -15px;

          & .container {
            padding-left: 15px;
          }
        }
      }

      &:nth-of-type(11n + 5) {
        grid-column: 7 / 13;

        margin-top: 60%;
        margin-right: -20px;

        & .container {
          padding-right: 20px;
        }

        @media (max-width: 767px) {
          grid-column: 2 / 7;

          margin-top: 0;
          margin-right: 0;

          & .container {
            padding-right: 0;
          }
        }
      }

      &:nth-of-type(11n + 6) {
        grid-column: 1 / 5;

        margin-top: -34%;

        @media (max-width: 767px) {
          grid-column: 3 / 7;

          margin-top: 0;
        }
      }

      &:nth-of-type(11n + 7) {
        grid-column: 1 / 7;

        margin-top: 34%;
        margin-left: -20px;

        & .container {
          padding-left: 20px;
        }

        @media (max-width: 767px) {
          grid-column: 1 / 5;

          margin-top: 0;
          margin-left: 0;

          & .container {
            padding-left: 0;
          }
        }
      }

      &:nth-of-type(11n + 8) {
        grid-column: 7 / 11;

        margin-top: -17%;

        @media (max-width: 767px) {
          grid-column: 2 / 7;

          margin-top: 0;
          margin-left: 0;
          margin-right: -15px;

          & .container {
            padding-left: 0;
            padding-right: 15px;
          }
        }
      }

      &:nth-of-type(11n + 9) {
        grid-column: 9 / 13;

        margin-top: -34%;
        margin-right: -20px;

        & .container {
          padding-right: 20px;
        }

        @media (max-width: 767px) {
          grid-column: 3 / 7;

          margin-top: 0;
          margin-right: 0;

          & .container {
            padding-right: 0;
          }
        }
      }

      &:nth-of-type(11n + 10) {
        grid-column: 2 / 6;

        margin-top: -16%;

        @media (max-width: 767px) {
          margin-top: 0;
          grid-column: 1 / 4;

          margin-left: -15px;

          & .container {
            padding-left: 15px;
          }
        }
      }

      &:nth-of-type(11n + 11) {
        grid-column: 7 / 13;

        margin-top: 60%;
        margin-right: -20px;

        & .container {
          padding-right: 20px;
        }

        @media (max-width: 767px) {
          grid-column: 2 / 7;

          margin-top: 0;
          margin-right: 0;

          & .container {
            padding-right: 0;
          }
        }
      }
    }
  }
`;

const Product = styled.div`
  margin: 0 0 60px 0;

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.thumbnail {
      visibility: visible;
    }
    &.hover-thumbnail {
      visibility: hidden;
    }
  }

  &.has-hover-thumbnail:hover {
    & .hover-thumbnail {
      visibility: visible;
    }
    & .thumbnail {
      visibility: hidden;
    }
  }

  & .container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 960px) {
      grid-template-columns: 1fr auto;
    }

    margin: 10px 0 0 0;

    & p,
    & h2 {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.03em;
      text-transform: lowercase;

      margin: 0;

      @media (max-width: 600px) {
        font-size: 9px;
        line-height: 12px;
      }
    }

    & p.year {
      font-size: 12px;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  &.gallery-view {
    & .container {
      opacity: 0;
    }

    &:hover {
      & .container {
        opacity: 1;
      }
    }
  }

  @media (max-width: 767px) {
    &.gallery-view {
      & .container {
        opacity: 1;
      }
    }
  }
`;

const SingleObject = ({ index, object }) => (
  <Product
    data-nosnippet
    className={`object gallery-view ${
      object.object.document.data.hover_thumbnail.fluid !== null
        ? `has-hover-thumbnail`
        : `no-hover-thumbnail`
    }`}
  >
    <ConditionalWrapper
      condition={index > 3}
      wrapper={(children) => (
        <LazyLoad offset={300} height={600} once>
          {children}
        </LazyLoad>
      )}
    >
      <ConditionalWrapper
        condition={object.object.document.data.shopify_product !== null}
        wrapper={(children) => (
          <Link to={`${object.object.document.url}`}>{children}</Link>
        )}
      >
        <AspectRatioImageContainer
          image={null}
          padding={
            (object.object.document.data.thumbnail.dimensions.height /
              object.object.document.data.thumbnail.dimensions.width) *
            100
          }
        >
          {object.object.document.data.thumbnail.fluid !== null && (
            <img
              className="thumbnail"
              srcSet={object.object.document.data.thumbnail.fluid.srcSet}
              src={object.object.document.data.thumbnail.fluid.src}
              alt={object.object.document.data.thumbnail.alt}
              loading="lazy"
            />
          )}

          {object.object.document.data.hover_thumbnail.hasOwnProperty(
            "fluid"
          ) &&
            object.object.document.data.hover_thumbnail.fluid !== null && (
              <img
                className="hover-thumbnail"
                srcSet={
                  object.object.document.data.hover_thumbnail.fluid.srcSet
                }
                src={object.object.document.data.hover_thumbnail.fluid.src}
                alt={object.object.document.data.hover_thumbnail.alt}
                loading="lazy"
              />
            )}
        </AspectRatioImageContainer>

        <div className="container">
          <div className="left">
            {object.object.document.data.shopify_product !== null && (
              <>
                <p className="maker" data-nosnippet>
                  {
                    FormatProductTitle(
                      object.object.document.data.shopify_product.title
                    )[0]
                  }
                </p>
                <h2 className="title" data-nosnippet>
                  {
                    FormatProductTitle(
                      object.object.document.data.shopify_product.title
                    )[1]
                  }
                </h2>
              </>
            )}
          </div>

          <div className="right">
            <p className="year" data-nosnippet>
              {object.object.document.data.year}
            </p>
          </div>
        </div>
      </ConditionalWrapper>
    </ConditionalWrapper>
  </Product>
);

export const GalleryLayout = ({
  collections,
  activeColor,
  activeMaterial,
  activeMaker,
  activeCategory,
  activeCollection,
}) => {
  const allCollections = collections
    .filter((collection) => {
      if (activeCollection === "All") {
        return collection;
      } else {
        return collection.primary.title.text.toLowerCase() === activeCollection;
      }
    })
    .map((collection, outerIndex) => {
      const collectionObjects = collection.items
        .filter((object) => object.object.document !== null)
        .filter(
          (object) => object.object.document.data.shopify_product !== null
        )
        .filter((object) => {
          if (activeColor === "All") {
            return object;
          } else {
            return object.object.document.data.colours
              .filter((colour) => colour.single_colour !== null)
              .map((colour) => colour.single_colour.toLowerCase())
              .includes(activeColor);
          }
        })
        .filter((object) => {
          if (activeMaterial === "All") {
            return object;
          } else {
            return object.object.document.data.filter_materials
              .filter((material) => material.single_material !== null)
              .map((material) => material.single_material.toLowerCase())
              .includes(activeMaterial);
          }
        })
        .filter((object) => {
          if (activeMaker === "All") {
            return object;
          } else {
            if (object.object.document.data.maker.document !== null) {
              return (
                object.object.document.data.maker.document.data.name.text.toLowerCase() ===
                activeMaker
              );
            }
          }
        })
        .filter((object) => {
          if (activeCategory === "All") {
            return object;
          } else {
            return object.object.document.data.categories
              .filter((category) => category.single_category !== null)
              .map((category) => category.single_category.toLowerCase())
              .includes(activeCategory);
          }
        })
        .map((object, index) => {
          return (
            <SingleObject
              index={index}
              object={object}
              key={`single_product_gallery_layout_${index}_${object.object.id}`}
            />
          );
        });

      return (
        <div
          id={`collection-${kebabCase(collection.primary.title.text)}`}
          key={`single_shop_collection_${outerIndex}`}
        >
          <Grid className={`gallery-view`} data-nosnippet>
            {collection.primary.thumbnail.fluid !== undefined &&
              collection.primary.thumbnail.fluid !== null && (
                <div className="object grid-view collection-image">
                  <img
                    srcSet={collection.primary.thumbnail.fluid.srcSet}
                    src={collection.primary.thumbnail.fluid.src}
                    alt={collection.primary.thumbnail.alt}
                    loading="lazy"
                  />
                </div>
              )}

            {collectionObjects}
          </Grid>
        </div>
      );
    });

  return allCollections;
};
